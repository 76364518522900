import styled from '@emotion/styled';
import { IconButton, TextField } from '@mui/material';
import React from 'react';
import { RenderElementProps, useSelected } from 'slate-react';
import { BlockTitle, BlockWrapper } from '../../elements/Common';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';
import { PowerEditorBase } from '../../slate/PowerEditorBase';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { usePanel } from './usePanel';
import { ActionRow, OccupyFreeSpace } from '../../../primitives';
import { ExpandLess, ExpandMore, Title } from '@mui/icons-material';
import { useIntl } from 'react-intl';


export const PanelBlockElementType = "panel-block";

export const PanelWrapper = styled(BlockWrapper)`
    & > div:last-child {
        width: 100%;
    }
`;


export const PanelBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;

    const isSelected = useSelected();
    const { formatMessage } = useIntl();

    const { viewMode } = usePowerEditorContext();
    
    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    const panel = usePanel(element);

    return (
        <PanelWrapper
          {...attributes}
          backgroundColor={(props.element as any)?.block_panel_background}
          textColor={(props.element as any)?.block_panel_color}
          className="panel-block-element"
          isSelected={isSelected}
          viewMode={viewMode}>
            {children}
            <div style={{ width: "100%" }} contentEditable={false}>
              {!viewMode && <BlockTitle element={props.element} />}
              <ActionRow firstItemNoMargin itemMarginTop='0' lastItemMarginRight='0'>
                {!viewMode && panel.hasTitle && <TextField
                    value={panel.title || ""}
                    onChange={e => panel.update({ title: e.target.value })}
                    label=""
                    placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.title"})}
                    fullWidth
                    />}

                {(viewMode || !panel.hasTitle) && <OccupyFreeSpace />}
                
                {!viewMode && <IconButton size="small" onClick={e => panel.toggleHasTitle()}>
                    <Title />
                </IconButton>}
                {!viewMode && <IconButton size="small" onClick={e => panel.update({ isEditorCollapsed: !panel.isEditorCollapsed})}>
                    {!panel.isEditorCollapsed ? <ExpandLess /> : <ExpandMore />}
                </IconButton>}
              </ActionRow>
              
              {!panel.isEditorCollapsed && <EditorComponent
                  content={panel.content}
                  update={v => panel.updateContent(v)}
                  viewMode={viewMode}
                  />}
            </div>
        </PanelWrapper>
    );
}
